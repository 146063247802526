module.exports = {
  xinyong:"مستوى الرصيد",
  chong_zhi_str: "يرجى الاتصال بخدمة العملاء عبر الإنترنت للشحن",
  zh_ze: "الرصيد الإجمالي للحساب",
  vstr_0_0: "إعادة تعيين الحد الأدنى",
vstr_1_0: "تطبيق الأمثلية",
vstr_2_0: "3 سحب في اليوم",
vstr_3_0: "الحد الأقصى لثلاث مجموعات من الطلبات في اليوم",
vstr_4_0: "استلام مجموعة من مهام بيانات تطبيقات 40/40",
vstr_5_0: "الحد الأقصى ",
vstr_6_0: " طلب في اليوم",
vstr_7_0: "ربح ",
vstr_8_0: " لكل تطبيق",
vstr_0_1: "إعادة تعيين الحد الأدنى ",
vstr_1_1: "تطبيق الأمثلية",
vstr_2_1: "5 سحب في اليوم",
vstr_3_1: "الحد الأقصى لثلاث مجموعات من الطلبات في اليوم",
vstr_4_1: "استلام مجموعة من مهام بيانات تطبيقات 50/50",
vstr_5_1: "الحد الأقصى ",
vstr_6_1: " طلب في اليوم",
vstr_7_1: "ربح ",
vstr_8_1: " لكل تطبيق",
vstr_0_2: "إعادة تعيين الحد الأدنى ",
vstr_1_2: "تطبيق الأمثلية",
vstr_2_2: "8 سحب في اليوم",
vstr_3_2: "الحد الأقصى لأربع مجموعات من الطلبات في اليوم",
vstr_4_2: "استلام مجموعة من مهام بيانات تطبيقات 55/55",
vstr_5_2: "الحد الأقصى ",
vstr_6_2: " طلب في اليوم",
vstr_7_2: "ربح ",
vstr_8_2: " لكل تطبيق",
vstr_0_3: "إعادة تعيين الحد الأدنى ",
vstr_1_3: "تطبيق الأمثلية",
vstr_2_3: "10 سحب في اليوم",
vstr_3_3: "الحد الأقصى لخمس مجموعات من الطلبات في اليوم",
vstr_4_3: "استلام مجموعة من مهام بيانات تطبيقات 60/60",
vstr_5_3: "الحد الأقصى ",
vstr_6_3: " طلب في اليوم",
vstr_7_3: "ربح ",
vstr_8_3: " لكل تطبيق",

  copy_str: "نسخ",
  hd_yj: "الحصول على عمولة",
    ky_ye: "الرصيد المتاح",
    home:'الصفحة الرئيسية',
    about:'عن',
    order:'طلب',
    record:'سجل الطلب',
    user:'مِلكِي',
    number_x:'كمية',
    about_text1:'سلع ذات جودة',
    about_text2:'المزيد من المنتجات',
    order_title:'مهمة',
    zongzichan:'إجمالي الأصول',
    jinridan:'كمية الطلب',
    tiyanjin:'صندوق الخبرة',
    jinrishou:'دخل اليوم',
    jintuandui:'لجنة فريق اليوم',
    dianjigou:'بدء المهمة',
    adianjigou:'يُقدِّم',
    order_record:'سجل الطلب',
    all:'الجميع',
    undone:'التراجع',
    completed:'مكتمل',
    score:'نتيجة',
    benutzer:'مستخدم',
    chongzhi:'فوق حتى',
    tixian:'ينسحب',
    details:'تفاصيل الحساب',
    password:'كلمة سر الدخول',
    password_jy:'كلمة مرور السحب',
    notify:'إشعار النظام',
    kefu:'خدمة الزبائن',
    bank_card:'ربط العنوان',
    invite:'ادعو أصدقاء',
    team:'تقرير الفريق',
    language:'لغة',
    member:'ترقية الأعضاء',
    balance:'رصيد الحساب',
    quit:'يترك',
    invite_text1:'???? ???????? ???? ??????',
    invite_code:'شفرة الدعوة',
    invite_btn:'???? ??????: ???? ????',
    copy_s:'تكرار',
    copy_b:'نسخ فشل',
    grade:'مستوى العضوية',
    my_money:'رصيد الحساب',
    open_member:'انضم إلى العضوية',
    withdraw_num:'عدد الانسحاب',
    day:'يوم',
    withdraw_quota:'انسحاب',
    order_num:'كمية النظام',
    profit_scale:'نسبة العمولة',
    member_time:'عضو دائم وفعال',
    confirm_pay:'تأكيد الدفع',
    order_sub:'أكد الطلب',
    user_info:'معلومات المستخدم',
    avatar:'الصورة الرمزية',
    username:'اسم المستخدم',
    set_up:'إعداد على الفور',
    revise_name:'تعديل الاسم',
    username_hint:'?????? ????? ??? ????????',
    user_account:'حساب المستخدم',
    add_money:'فوق حتى',
    add_money_num:'إعادة شحن المبلغ',
    add_money_hint1:'1. ??? ?? ???? ???? ????? ?????? ?? ???? ????? ? ???? ??? ??? ?????? ???? ????????',
    add_money_hint2:'2. ??? ?? ???? ????? ????????? ? ????? ??????? ???? ??????? ???????? ?? ??? ???????? ??????',
    add_money_hint3:'???? ??????? ????? ??????? ?????? ??? ??????',
    add_money_record:'سجل إعادة الشحن',
    withdraw_record:'سجل السحب',
    withdraw_money:'كمية السحب',
    can_withdraw_money:'يمكن ذكر المبلغ',
    order_number:'SN',
    money:'كمية',
    bank:'BANK',
    transaction_flow:'رقم تدفق التداول',
    already_paid:'لقد دفعت',
    upload_pay_img:'????? ???? ??????',
    order_details:'تفاصيل الحساب',
    old_password:'???? ?????? ???????',
    new_password:'كلمة مرور جديدة',
    repeat_password:'????? ???? ??????',
    enter:'تفضل',
    password_hint:'???? ?????? ???? ?????? ? ???? ??????? ????? ??????? ??? ???? ???? ??????',
    submit:'إرسال',
    bankCard:'ربط العنوان',
    bank:'فتح حساب',
    bank_card_num:'رقم بطاقة البنك',
    bank_card_name:'اسم حامل البطاقة',
    team:'تقرير الفريق',
    today:'اليوم',
    yesterday:'أمس',
    week:'هذا الاسبوع',
    scale:'حَجم',
    team_people:'عدد الفرق',
    team_order_scale:'لجنة أمر الفريق',
    open_bank_name:'إسم الحساب',
    phone:'رقم التليفون',
    user_password:'كلمة المرور',
    login:'تسجيل دخول',
    register:'يسجل',
    password_qr:'????? ???? ??????',
    pwd_no_same:'???? ???? ??? ???????',
    loading:'تحميل',
    freeze:'تجميد',
    pending:'قيد الانتظار',
    order_hao:'رقم الأمر',
    order_time:'مرة واحدة',
    price:"سعر الوحدة",
    order_total_price:'من أجل الكاملة',
    scale:'عمولة',
    level:'مستوى العضوية',
    level_up:'ترقية الأعضاء',
    pay_way:'????? ????? ?????',
    money_min:'صغير جدا',
    pay_no:'?????? ????? ????? ????? ?????',
    // 新加
    team_all:'الجميع',
    team_yi:'مستوى اول',
    team_er:'المرحلة الثانوية',
    team_san:'ثلاثة مستوى',
    close_order:'الغاء الطلب',
    shouru:'دخل',
    zhichu:'المصروفات',
    welcome:'مرحباً',
    order_kong:'?? ???? ?? ???? ??? ????? ??????',
    quer:'يتأكد',
    quxiao:'يلغي',
    qianbaodi:'عنوان محفظة',
    xingming:'اسم',
    bank_chong:'?? ???? ????? ?????',
    introduce:'ملف الشركة',
    platform:'قواعد المنصة',
    tixianzhang:'حساب السحب',
    xuanze:'اختر من فضلك',
    xiayiye:'???? ?????? ?????? ???????',
    // 新加2
    certificate:'شهادة',
    faq:'FAQ',
    tc:'T&C',
    guanyu_wm:'معلومات عنا',
    zuixin_sj:'آخر حادث',
    usdt_tx:'انسحاب USDT',
    bank_tx:'??? ??????? ????????',
    bank_tx_kf:'???? ??????? ????? ??????? ??? ????????',
    dongjie_ye:'المبلغ المجمد',
    zanwu_sj:'لايوجد بيانات'
  }