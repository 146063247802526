import '@/assets/common.scss'
import 'vant/lib/index.css';
import {i18n,vantLocales} from '@/i18n/i18n';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
vantLocales(i18n.locale)
createApp(App).use(store).use(router).use(i18n).mount('#app')
