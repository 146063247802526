module.exports = {
  chong_zhi_str: "Xahiş olunur onlayn xidmət əlaqə saxlasın və yenidən şarj edin",
  zh_ze: "Hesabın ümumi qalığı",
  vstr_0_0: "Minimum reset ",
vstr_1_0: "Optimallaşdırma Tətbiqi",
vstr_2_0: "Gündə 3 geri çəkilmə",
vstr_3_0: "Gündə maksimum 3 qrup sifariş",
vstr_4_0: "40/40 tətbiq məlumat tapşırıqları almaq",
vstr_5_0: "Maksimum ",
vstr_6_0: " sifariş gündədə",
vstr_7_0: "Hər bir tətbiq üçün ",
vstr_8_0: " qazanc",
vstr_0_1: "Minimum reset ",
vstr_1_1: "Optimallaşdırma Tətbiqi",
vstr_2_1: "Gündə 5 geri çəkilmə",
vstr_3_1: "Gündə maksimum 3 qrup sifariş",
vstr_4_1: "50/50 tətbiq məlumat tapşırıqları almaq",
vstr_5_1: "Maksimum ",
vstr_6_1: " sifariş gündədə",
vstr_7_1: "Hər bir tətbiq üçün ",
vstr_8_1: " qazanc",
vstr_0_2: "Minimum reset ",
vstr_1_2: "Optimallaşdırma Tətbiqi",
vstr_2_2: "Gündə 8 geri çəkilmə",
vstr_3_2: "Gündə maksimum 4 qrup sifariş",
vstr_4_2: "55/55 tətbiq məlumat tapşırıqları almaq",
vstr_5_2: "Maksimum ",
vstr_6_2: " sifariş gündədə",
vstr_7_2: "Hər bir tətbiq üçün ",
vstr_8_2: " qazanc",
vstr_0_3: "Minimum reset ",
vstr_1_3: "Optimallaşdırma Tətbiqi",
vstr_2_3: "Gündə 10 geri çəkilmə",
vstr_3_3: "Gündə maksimum 5 qrup sifariş",
vstr_4_3: "60/60 tətbiq məlumat tapşırıqları almaq",
vstr_5_3: "Maksimum ",
vstr_6_3: " sifariş gündədə",
vstr_7_3: "Hər bir tətbiq üçün ",
vstr_8_3: " qazanc",

  copy_str: "Kopyala",
  hd_yj: "Komissiya almaq",
    ky_ye: "İstifadəyə qoymaq üçün balans",
  home:'Ana s?hif?',
  about:'Haqq?nda',
  order:'Sifari?',
  record:'Sifari? qeydl?ri',
  user:'M?nim',
  number_x:'Quantitas',
    about_text1:'Keyfiyy?tli mallar',
    about_text2:'Daha çox məhsul',
    
order_title:'Tap??r?q',
zongzichan:'ümumi varidat',
jinridan:'Sifari? say?',
tiyanjin:'T?crüb? fondu',
jinrishou:'Bu gün g?lir',
jintuandui:'Bu gün komanda bonusu',
dianjigou:'Tap??r??? ba?lay?n',
adianjigou:'G?nd?r',
order_record:'Sifari? qeydl?ri',
all:'Ham?s?',
undone:'Tamamlanmam??',
completed:'Tamamlanm??',
score:'Bax??',
benutzer:'?stifad??i',
chongzhi:'Yükl?m?',
tixian:'??x??',
details:'Hesab detallar?',
password:'Giri? ?ifr?si',
password_jy:'?m?liyyat ?ifr?si',
notify:'Sistem Bildiri?i',
kefu:'Xidm?t',
bank_card:'Ünvanı bağlamaq',
invite:'Dostlar?n? d?v?t et',
team:'Komanda hesabat?',
language:'Dil se?imi',
member:'üzv s?viyy?si',
balance:'Hesab balans?',
quit:'??x?? et',
invite_text1:'Dostlar?n? d?v?t et v? pul qazan',
invite_code:'D?v?t kodu',
invite_btn:'D?v?t linki: Kopyalamaq ü?ün bas?n',
copy_s:'Kopyalama u?urlu oldu',
copy_b:'Kopyalama al?nmad?',
grade:'?stifad??i s?viyy?si',
my_money:'M?nim hesab?m?n balans?',
open_member:'?stifad??i üzvü a?',
withdraw_num:'??x?? say?',
day:'Gün',
withdraw_quota:'??x?? limiti',
order_num:'Sifari? say?',
profit_scale:'Komissiya faizi',
member_time:'?stifad??i üzvü müdd?tsizdir',
confirm_pay:'?d?ni?i t?sdiql?',
order_sub:'Sifari?i t?qdim et',
user_info:'?stifad??i m?lumat?',
avatar:'Avatar',
username:'?stifad??i ad?',
set_up:'Düz?ltm?y? ba?la',
revise_name:'Ad? d?yi?dir',
username_hint:'?stifad??i ad?n? daxil edin',
user_account:'?stifad??i hesab?',
add_money:'Yükl?m?',
add_money_num:'Yükl?m? m?bl??i',
add_money_hint1:'1. ?d?ni? m?bl??i sifari? m?bl??i il? uy?un olmal?d?r, ?ks halda avtomatik olaraq yüklenm?z',
add_money_hint2:'2. Yükl?m? v? ??x?? al?nmazsa, dig?r probleml?ri h?ll etm?k ü?ün müraci?t edin',
add_money_hint3:'Xidm?t haqq?n? ?yr?nm?k ü?ün xidm?t? müraci?t edin',
add_money_record:'Yükl?m? qeydl?ri',
withdraw_record:'??x?? qeydl?ri',
withdraw_money:'??x?? m?bl??i',
can_withdraw_money:'??x?? edil? bil?n m?bl??',
order_number:'SN',
money:'M?bl??',
bank:'BANK',
transaction_flow:'?m?liyyat kodu',
already_paid:'?d?ni? edildi',
upload_pay_img:'?d?ni? ekran ??kli',
order_details:'Hesab detallar?',
old_password:'K?hn? ?ifr?',
new_password:'Yeni ?ifr?',
repeat_password:'T?krar ?ifr?',
enter:'Daxil edin',
password_hint:'?ifr?ni yadda saxlay?n, ?ifr?ni unutars?n?zsa xidm?t? müraci?t edin',
submit:'G?nd?r',
bankCard:'Ünvanı bağlamaq',
bank:'Banka',
bank_card_num:'Banka kart? n?mr?si',
bank_card_name:'Kart sahibinin ad?',
team:'Komanda hesabat?',
today:'Bu gün',
yesterday:'Dün?n',
week:'Bu h?ft?',
scale:'Nisb?t',
team_people:'Komanda üzvl?ri',
team_order_scale:'Komanda sifari?l?r komissiyas?',
open_bank_name:'Bank?n ad?',
phone:'Telefon n?mr?si',
user_password:'?ifr?',
login:'Daxil ol',
register:'Qeydiyyatdan ke?',
password_qr:'?ifr?ni t?sdiql?',
pwd_no_same:'?ifr?l?r uy?un deyil',
loading:'Yükl?nir',
freeze:'M?hdudla?d?rmaq',
pending:'G?zl?y?n',
order_hao:'Sifari? n?mr?si',
order_time:'Sifari? vaxt?',
price:"Qiym?t",
order_total_price:'Sifari? ümumi d?y?ri',
scale:'Komissiya',
level:'?stifad??i s?viyy?si',
level_up:'S?viyy?ni yüks?lt',
pay_way:'?d?ni? üsulu',
money_min:'M?bl?? ?ox ki?ikdir',
pay_no:'Xahi? edirik ?d?ni? üsulunu se?in',
team_all:'Ham?s?',
team_yi:'Birinci',
team_er:'?kinci',
team_san:'ü?üncü',
close_order:'Sifari?i l??v edin',
shouru:'G?lir',
zhichu:'X?rc',
welcome:'Xo? g?lmisiniz',
order_kong:'Sifari? n?mr?si bo? ola bilm?z',
quer:'T?sdiq',
quxiao:'L??v et',
qianbaodi:'Kart xidm?ti ünvan?',
xingming:'Ad',
    
bank_chong:'T?krar ba?laya bilm?zsiniz',
introduce:'?irk?t t?qdimat?',
platform:'Platforma qaydalar?',
tixianzhang:'N??d ??x?? hesab?',
xuanze:'Xahi? edirik se?in',
xiayiye:'N?vb?ti s?hif?ni yükl?m?k ü?ün klikl?yin',
certificate:'S?n?d',
faq:'FAQ',
tc:'T&C',
guanyu_wm:'Bizim haqq?m?zda',
zuixin_sj:'?n son hadis?l?r',
usdt_tx:'USDT n??d ??x???',
bank_tx:'Bank kart? il? n??d ??x??',
bank_tx_kf:'Xahi? edirik onlayn xidm?tl? ?laq? saxlay?n',
dongjie_ye:'Müst?qil qal?q',
zanwu_sj:'Məlumat yoxdur'
  }