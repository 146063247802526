import "core-js/modules/web.timers.js";
import { useI18n } from 'vue-i18n';
import store from '@/store/index';
import { vantLocales } from '@/i18n/i18n';
import { unReadMsgCheck, readMsg } from '@/api/api';
import { showDialog } from 'vant';
export default {
  setup: function setup() {
    // 多语言
    var _useI18n = useI18n(),
      locale = _useI18n.locale;
    var lang = localStorage.getItem('lang') || 'en-en';
    locale.value = lang;
    store.dispatch('changelang', lang);
    vantLocales(lang);
    // get_lang().then((res)=>{
    //    console.log('lang',res)
    // })
    // is_recharge().then(res=>{
    //   if(res.open_recharge){
    //     return store.dispatch('changeOpenRecharge',true)
    //   }
    //   store.dispatch('changeOpenRecharge',false)
    // })

    setInterval(function () {
      unReadMsgCheck({
        noLoading: 1
      }).then(function (res) {
        var userUnReadMsgId;
        if (res.status) {
          userUnReadMsgId = res.data.id;
          showDialog({
            title: res.data.title,
            message: res.data.content
          }).then(function () {
            readMsg({
              id: userUnReadMsgId
            }).then(function (res) {
              // console.log({ res })
            });
            // on close
          });
        }
      });
    }, 1000 * 6);

    // 弹窗
    sessionStorage.setItem('app_pop', true);
  }
};