module.exports = {
  xinyong:"Kreditwürdigkeit",
  chong_zhi_str: "Bitte kontaktieren Sie den Online-Kundenservice, um aufzuladen",
  zh_ze: "Gesamtguthaben des Kontos",
  vstr_0_0: "Mindestrückstellung ",
vstr_1_0: "Optimierungs-App",
vstr_2_0: "3 Auszahlungen pro Tag",
vstr_3_0: "Maximal 3 Gruppen von Bestellungen pro Tag",
vstr_4_0: "Erhalten Sie einen Satz von 40/40 App-Datenaufgaben",
vstr_5_0: "Maximal ",
vstr_6_0: " Bestellungen pro Tag",
vstr_7_0: "Gewinn von ",
vstr_8_0: " pro Anwendung",
vstr_0_1: "Mindestrückstellung ",
vstr_1_1: "Optimierungs-App",
vstr_2_1: "5 Auszahlungen pro Tag",
vstr_3_1: "Maximal 3 Gruppen von Bestellungen pro Tag",
vstr_4_1: "Erhalten Sie einen Satz von 50/50 App-Datenaufgaben",
vstr_5_1: "Maximal ",
vstr_6_1: " Bestellungen pro Tag",
vstr_7_1: "Gewinn von ",
vstr_8_1: " pro Anwendung",
vstr_0_2: "Mindestrückstellung ",
vstr_1_2: "Optimierungs-App",
vstr_2_2: "8 Auszahlungen pro Tag",
vstr_3_2: "Maximal 4 Gruppen von Bestellungen pro Tag",
vstr_4_2: "Erhalten Sie einen Satz von 55/55 App-Datenaufgaben",
vstr_5_2: "Maximal ",
vstr_6_2: " Bestellungen pro Tag",
vstr_7_2: "Gewinn von ",
vstr_8_2: " pro Anwendung",
vstr_0_3: "Mindestrückstellung ",
vstr_1_3: "Optimierungs-App",
vstr_2_3: "10 Auszahlungen pro Tag",
vstr_3_3: "Maximal 5 Gruppen von Bestellungen pro Tag",
vstr_4_3: "Erhalten Sie einen Satz von 60/60 App-Datenaufgaben",
vstr_5_3: "Maximal ",
vstr_6_3: " Bestellungen pro Tag",
vstr_7_3: "Gewinn von ",
vstr_8_3: " pro Anwendung",

  copy_str: "Kopieren",
  hd_yj: "Provision erhalten",
    ky_ye: "Verfügbarer Saldo",
    banner1_text:'Das Leben ist zu kurz, um Zeit mit Menschen zu verbringen, die einem das Glück aussaugen. Wenn jemand Sie in seinem Leben haben möchte, wird er Platz für Sie schaffen.',
    leistungsumfang:'Leistungsumfang',
    leistungsumfang_text:'Derzeit beliefern wir mehr als 100.000 Kunden und erhalten gute Bewertungen',
    leistungsumfang_free:'KOSTENLOSE ÜBERTRAGUNG',
    home:'Titelseite',
    about:'um',
    order:'Befehl',
    record:'Aufzeichnen',
    user:'meins',
    number_x:'Menge',
    about_text1:'Qualitätsware',
    about_text2:'Mehr Produkte',
    order_title:'Die heutige Bestellung',
    zongzichan:'Gesamtvermögen',
    jinridan:'Anzahl der Bestellungen heute',
    tiyanjin:'Erfahrungsfonds',
    jinrishou:'Heutiges Einkommen',
    jintuandui:'Heute Mannschaftskommission',
    dianjigou:'Klicken Sie hier, um zu bestellen',
    adianjigou:'Klicken Sie hier, um zu bestellen',
    order_illustrate:'Die Plattform übernimmt keine Verantwortung, wenn Geld fälschlicherweise auf Ihr Bankkonto überwiesen wird. Der Mindestauszahlungsbetrag beträgt 50.000 Won.',
    order_record:'Bestelldatensatz',
    all:'alle',
    undone:'rückgängig gemacht',
    completed:'vollendet',
    score:'Punktzahl',
    benutzer:'Benutzer',
    chongzhi:'nachfüllen',
    tixian:'zurückziehen',
    details:'Kontodetails',
    password:'Passwortverwaltung',
    password_jy:'Transaktions Passwort',
    notify:'Systembenachrichtigung',
    kefu:'Kundendienst',
    bank_card:'Adresse verbinden',
    invite:'Freunde einladen',
    team:'Mannschaftsbericht',
    language:'Wählen Sie eine Sprache',
    member:'Mitglieder-Upgrade',
    balance:'Kontostand',
    quit:'Abmelden',
    invite_text1:'Laden Sie Freunde ein, um Geld zu verdienen',
    invite_code:'Einladungscode',
    invite_btn:'Einladungslink: Zum Kopieren klicken',
    copy_s:'Erfolgreich kopiert',
    copy_b:'Das Kopieren ist fehlgeschlagen',
    grade:'Mitgliedschaftsstufe',
    my_money:'Meinen Kontostand',
    open_member:'Mitglied werden',
    withdraw_num:'Anzahl der Abhebungen',
    day:'Himmel',
    withdraw_quota:'Auszahlungslimit',
    order_num:'Bestellmenge',
    profit_scale:'Provisionssatz',
    member_time:'Die Mitgliedschaft ist für immer gültig',
    confirm_pay:'bestätige Zahlung',
    order_sub:'Bestellung abschicken',
    user_info:'Benutzerinformation',
    avatar:'Benutzerbild',
    username:'Nutzername',
    set_up:'Jetzt einrichten',
    revise_name:'Namen ändern',
    username_hint:'Bitte geben Sie Ihren Benutzernamen ein',
    user_account:'Benutzerkonto',
    add_money:'nachfüllen',
    add_money_num:'Aufladebetrag',
    add_money_hint1:'1.Der Zahlungsbetrag muss mit dem Bestellbetrag übereinstimmen, sonst kommt er nicht automatisch an',
    add_money_hint2:'2.Wenn die Aufladung und Auszahlung nicht eintrifft, wenden Sie sich bitte an Ihren Vorgesetzten oder den Kundendienst, um andere Probleme zu lösen.',
    add_money_hint3:'Für Gebühren wenden Sie sich bitte an den Kundendienst',
    add_money_record:'Aufladeaufzeichnung',
    withdraw_record:'Abhebungsprotokoll',
    withdraw_money:'Auszahlungsbetrag',
    can_withdraw_money:'Verfügbarer Bargeldbetrag',
    order_number:'SN',
    money:'Menge',
    bank:'BANK',
    order_num:'Seriennummer der Transaktion',
    already_paid:'Ich habe bezahlt',
    upload_pay_img:'Laden Sie den Zahlungs-Screenshot hoch',
    order_details:'Kontodetails',
    old_password:'Altes Passwort',
    new_password:'Neues Kennwort',
    repeat_password:'Passwort wiederholen',
    enter:'Bitte eingeben',
    password_hint:'Bitte merken Sie sich Ihr Passwort. Wenn Sie Ihr Passwort vergessen haben, wenden Sie sich bitte an den Kundendienst.',
    submit:'einreichen',
    bankCard:'Adresse verbinden',
    bank:'Kontoeröffnungsbank',
    bank_card_num:'Bankkartennummer',
    bank_card_name:'Name des Karteninhabers',
    team:'Teamberichte',
    today:'Heute',
    yesterday:'gestern',
    week:'diese Woche',
    scale:'Anteil',
    team_people:'Teamgröße',
    team_order_scale:'Team-Order-Provision',
    open_bank_name:'Kontoname',
    phone:'Telefonnummer',
    user_password:'Passwort',
    login:'Anmeldung',
    register:'registrieren',
    password_qr:'Bestätige das Passwort',
    pwd_no_same:'Passwörter sind inkonsistent',
    loading:'Wird geladen',
    freeze:'einfrieren',
    pending:'Ausstehend',
    order_num:'Bestellnummer',
    order_time:'Zeit zum Bestellen',
    price:"Einzelpreis",
    order_total_price:'Auftragssumme',
    scale:'Kommission',
    level:'Mitgliedschaftsstufe',
    level_up:'Mitglieder-Upgrade',
    pay_way:'Auflademethode',
    money_min:'Die Menge ist zu gering',
    pay_no:'Bitte wählen Sie eine Auflademethode aus',
    // 新加
    team_all:'alle',
    team_yi:'Level 1',
    team_er:'Level 2',
    team_san:'Stufe drei',
    close_order:'Bestellung stornieren',
    shouru:'Einkommen',
    zhichu:'Ausgaben',
    welcome:'Welcome',
    // 新加2
    order_kong:'Die Bestellnummer darf nicht leer sein',
    quer:'bestätigen',
    quxiao:'Stornieren',
    qianbaodi:'Wallet-Adresse',
    xingming:'Name',
    bank_chong:'Kann nicht wiederholt gebunden werden',
    introduce:'Unternehmensprofil',
    platform:'Plattformregeln',
    tixianzhang:'Konto abheben',
    xuanze:'bitte auswählen',
    xiayiye:'Klicken Sie hier, um die nächste Seite zu laden',
     // 新加2
     certificate:'Zertifikat',
     faq:'faq',
     tc:'tc',
     guanyu_wm:'über uns',
     zuixin_sj:'letzte Veranstaltungen',
     usdt_tx:'USDT abheben',
    bank_tx:'Bargeldabhebung per Bankkarte',
    bank_tx_kf:'Bitte wenden Sie sich an den Online-Kundendienst',
    dongjie_ye:'Guthaben einfrieren',
    zanwu_sj:'Keine Daten'
  }