import http from '@/api/request'
import qs from 'qs';

// 注册
export const do_register = (params) => {
  return http.post('api/user/do_register', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 登录
export const do_login = (params) => {
  return http.post('api/user/do_login', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 获取语言列表
export const get_lang = () => {
  return http.post('api/user/lang')
    .then((result) => {
      return result.data
    })
}
// 首页数据
export const rot_order = (params) => {
  return http.post('api/rot_order/index', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 抢单
export const submit_order = (params) => {
  return http.post('api/rot_order/submit_order', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 订单首页
export const order_index = (params) => {
  return http.post('api/order/index', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 订单数据
export const order_list = (params) => {
  return http.post('api/order/order_list', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 我的
export const getMyIndex = (params) => {
  return http.post('api/my/index', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 充值方式
export const recharge = () => {
  return http.post('api/ctrl/recharge')
    .then((result) => {
      return result.data
    })
}
// 充值规则校验
export const recharge_do_before = (params) => {
  return http.post('api/ctrl/recharge_do_before', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 支付信息
export const pay_bit = (params) => {
  if (params) {
    params = `?${qs.stringify(params)}`
  } else {
    params = ''
  }
  return http.post(`api/pay/bipay${params}`)
    .then((result) => {
      return result.data
    })

}
// 提交支付
export const pay_sub = (params) => {
  return http.post('api/pay/user_ok', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 获取订单信息
export const get_recharge_order = (params) => {
  return http.post('api/order/get_recharge_order', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 客服列表
export const support = (params) => {
  return http.post('api/support/index', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 系统通知
export const getMsg = (params) => {
  return http.post('api/my/msg', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 邀请好友
export const invite = (params) => {
  return http.post('api/my/invite', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 上传图片
export const upload_base64 = (params) => {
  return http.post('api/user/upload', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 绑定银行卡
export const headimg = (params) => {
  return http.post('api/my/headimg', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 设置登录密码
export const revisePassword = (params) => {
  return http.post('api/ctrl/set_pwd', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 设置交易密码
export const revisePasswordJy = (params) => {
  return http.post('api/ctrl/set_pwd2', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 团队报表
export const teamReports = (params) => {
  return http.post('api/ctrl/junior', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 处理订单
export const do_order = (params) => {
  return http.post('api/order/do_order', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// vip列表
export const vie_list = (params) => {
  return http.post('api/ctrl/vip', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 充值vip
export const recharge_dovip = (params) => {
  return http.post('api/ctrl/recharge_dovip', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 收益列表
export const caiwu = (params) => {
  return http.post('api/my/caiwu', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 修改用户名
export const edit_username = (params) => {
  return http.post('api/my/edit_username', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 银行卡
export const bind_bank = () => {
  return http.post('api/my/bind_bank2')
    .then((result) => {
      return result.data
    })
}
// 银行卡列表
export const getBankList = () => {
  return http.post('api/my/bind_bank3')
    .then((result) => {
      return result.data
    })
}
// 绑定银行卡
export const set_bank = (params) => {
  return http.post('api/my/bind_bank', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 提现
export const do_deposit = (params) => {
  return http.post('api/ctrl/do_deposit', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
// 支付开关
export const is_recharge = (params) => {
  return http.post('api/user/is_recharge', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
export const get_rule = (params) => {
  return http.post('api/my/msg2', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
//  logo跟弹框文本内容
export const sys_conf = (params) => {
  return http.post('api/user/sys_conf', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}
//  faq和tc
export const get_msg = (params) => {
  return http.post('api/index/get_msg', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}

export const unReadMsgCheck = (params) => {
  return http.post('api/my/unReadMsgCheck', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}


export const readMsg = (params) => {
  return http.post('api/my/readMsg', qs.stringify(params))
    .then((result) => {
      return result.data
    })
}


